import React from "react";
import { Routes, Route, Link } from "react-router-dom";

import Home from "./Home";
import SEO from "./SEO";
import WebDesDev from "./WebDesDev";
import MobileDev from "./MobileDev";
import Contact from "./Contact";
import GameDev from "./GameDev";

export default function index() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/mobile-development" element={<MobileDev />} />
      <Route path="/search-engine-optimization" element={<SEO />} />
      <Route path="/web-design-and-development" element={<WebDesDev />} />
      <Route path="/game-development" element={<GameDev />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}
