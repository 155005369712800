import React from "react";
import { init } from "@emailjs/browser";
import { Navbar } from "containers/Navbar";
import { Body } from "containers/Body";
import { Footer } from "containers/Footer";

import { default as Routes } from "routes";

init("RMjT7BWN6zHD1-qtz");

function App() {
  return (
    <>
      <Navbar />
      <Body>
        <Routes />
      </Body>
      <Footer />
    </>
  );
}

export default App;
