import React from "react";

import "./Body.css";

interface BodyProps {
  children: React.ReactNode;
}

export default function Body({ children }: BodyProps) {
  return <div className="body w-full">{children}</div>;
}
