import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<any> {
  children?: React.ReactNode;
  className?: string;
  color?: string;
}

export default function Button({
  children,
  className = "",
  color = "bg-blue-500",
  ...rest
}: ButtonProps) {
  return (
    <button
      className={`w-auto p-3 text-center hover:opacity-70 cursor-pointer ${color} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
