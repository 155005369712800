
import {default as aws_logo } from 'assets/services/aws.png';
import {default as css } from 'assets/services/css.png';
import {default as digital_ocean } from 'assets/services/digital-ocean.png';
import {default as django } from 'assets/services/django.png';
import {default as expo } from 'assets/services/expo.png';
import {default as html } from 'assets/services/html.png';
import {default as javascript } from 'assets/services/javascript.png';
import {default as nodejs } from 'assets/services/nodejs.png';
import {default as postgres } from 'assets/services/postgres.png';
import {default as python } from 'assets/services/python.png';
import {default as react } from 'assets/services/react.png';
import {default as wordpress } from 'assets/services/wordpress.png';

import {default as php } from 'assets/services/php.png';
import {default as mysql } from 'assets/services/mysql.png';
import {default as ue } from 'assets/services/UE.png';
import {default as unity } from 'assets/services/unity.jpg';
import {default as godot } from 'assets/services/godot.png';
import {default as raylib } from 'assets/services/Raylib.png';
import {default as sfml } from 'assets/services/sfml.png';

export{default as LOGO_250} from 'assets/logo-250.png';
export { default as BG_1 } from 'assets/bg-1.png';
export { default as robot } from 'assets/robot.jpg';
export { default as computer } from 'assets/computer-min.jpeg';

export const services = [
    html,
    css,
    javascript,
    nodejs,
    react,
    expo,
    aws_logo,
    digital_ocean,
    django,
    postgres,
    mysql,
    python,
    wordpress,
    php,
    ue,
    unity,
    godot,
    raylib,
    sfml
]

// export const LOGO_250 = logo_250;
// export const BG_1 = bg1;