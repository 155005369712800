import React from "react";
import { Button } from "components/Button";
import ContactForm from "components/Forms/ContactForm";
import { services, robot } from "../../constants";
import ContactSection from "containers/ContactSection";

export default function index() {
  return (
    <div className="">
      <div className="section-1 flex">
        <div className="container mx-auto">
          <div className="m-auto h-full flex flex-col justify-center text-center w-1/2 md:text-left md:w-full">
            <h1 className="text-5xl text-white mb-3">Innovative Solutions</h1>
            <p className="text-lg text-gray-300 mb-3">
              We bring dreams to reality.
            </p>
            <div className="block">
              <Button className="inline-block text-white">
                Connect With Us
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 container mx-auto p-10 text-center">
        <h2 className="text-4xl my-5 ubuntu-font">Our Technologies</h2>
        <div className="grid grid-cols-6 gap-4 items-center">
          {services.map((src: any) => (
            <img className="w-40 mx-auto p-5" src={src} />
          ))}
        </div>
      </div>

      <div className="section-3 bg-gray-100 text-center py-20">
        <div className="container mx-auto">
          <h2 className="text-4xl ubuntu-font mb-3">
            Welcome to OHMCODES Corporation
          </h2>

          <div className="grid grid-cols-3 md:grid-cols-3 md:grid-rows-1 md:auto-rows-min grid-flow-col gap-4 pt-5">
            <div className="col-span-full md:row-span-1 md:col-span-2 bg-white">
              <img src={robot} />
            </div>

            <div className="col-span-full md:row-span-1 md:col-start-3 bg-white p-10 flex">
              <p className="my-auto text-xl w-5/6 mx-auto">
                "OHMCODES is an elite software development firm
                located in Vancouver, Canada. SEO, Web and Mobile applications,
                cloud infrastructure, custom enterprise software platforms, or
                anything in between – you name it, they can build it." - Jerrick
                M.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-10 bg-computer">
        <div className="container mx-auto">
          <div className="text-white py-20">
            <div className="flex h-full">
              <div className="my-auto">
                <h2 className="text-left text-4xl mb-3">
                  Website Designing & Development
                </h2>
                <p className="text-left text-xl">
                  Our full stack web developers and designers are resolute to
                  achieve the demands of our customers to the very best of their
                  abilities. We hold our client's interests above ours & make
                  sure that nothing goes wrong in the whole process beginning
                  from initial conversation to the end deployment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactSection />
    </div>
  );
}
