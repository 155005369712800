import React from "react";

interface UniconProps {
  name: string;
  className?: string;
}

const Unicon: React.VFC<UniconProps> = ({ name, className = "" }) => (
  <i className={`uil uil-${name} ${className}`}></i>
);

export default Unicon;
