import React from "react";
import { LOGO_250 } from "../../constants";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <div className="w-full shadow-sm bg-white p-20">
        <div className="container mx-auto text-center">
          <div className="grid grid-cols-2 md:grid-cols-4">
            <div className="text-left">
              <div className="">
                <img className="h-20 mb-3" src={LOGO_250} />
              </div>
              <h4 className="font-bold">OHMCODES</h4>
              <h6 className="">123 W Broadway, Vancouver</h6>
            </div>
            <div className="text-left">
              <h4 className="font-bold">Links</h4>
              <ul className="list-unstyled ">
                <li className="text-left text-sm">Home</li>
                <li className="text-left text-sm">Solutions</li>
                <li className="text-left text-sm">About</li>
                <li className="text-left text-sm">Contact</li>
              </ul>
            </div>
            <div className="text-left">
              <h4 className="font-bold">Support</h4>
              <ul className="list-unstyled ">
                <li className="text-left text-sm">Home</li>
                <li className="text-left text-sm">Solutions</li>
                <li className="text-left text-sm">About</li>
                <li className="text-left text-sm">Contact</li>
              </ul>
            </div>
            <div className="text-left">
              <h4 className="font-bold">Blog</h4>
              <ul className="list-unstyled ">
                <li className="text-left text-sm">Home</li>
                <li className="text-left text-sm">Solutions</li>
                <li className="text-left text-sm">About</li>
                <li className="text-left text-sm">Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p className="mx-auto p-3 text-sm text-center text-gray-700 bg-gray-300 ubuntu-font">
        <small>Copyright (C) OHMCODES 2023</small>
      </p>
    </>
  );
}
