import React from "react";
import seoImg from "assets/images/seo.png";
import webDesignImg from "assets/images/web-design.png";
import searchImg from "assets/images/search.png";
import Page from "components/Page";
import ContactSection from "containers/ContactSection";

export default function WebDesDev() {
  return (
    <div>
      <Page title="Website Designing & Development">
        <Page.PageWrap>
          <Page.SectionWrapper>
            <Page.Image src={webDesignImg} />

            <Page.PaddedDiv>
              <Page.H2>
                Develop Stunning & High Performing websites that adds 360 degree
                value to your business
              </Page.H2>

              <Page.Paragraph>
                New World Innovations provides user-centric & conversion
                oriented website designing & development services to all our
                clients worldwide. We seek to develop fully functional websites
                that assist you to upscale your brand authority through research
                based on blueprints. But what makes us special, well we’ve
                gained expertise through years of experience in developing
                solutions for almost every industry with a promise to forge it
                to be feature-rich & digitally transforming. Although, years of
                research have gained us analytics into what works well and what
                doesn’t for a specific scenario. Thus, we compile what we’ve got
                & provide you the web solution you will adore for a lifetime.
              </Page.Paragraph>

              <Page.List>
                <span>Organize your Hosting with well secure domain</span>
                <span>Complete designing with API Integration</span>
                <span>Site Performance with Plugin updates</span>
                <span>Complete Onsite Speed Optimization</span>
              </Page.List>
            </Page.PaddedDiv>
          </Page.SectionWrapper>

          <Page.SectionWrapper left>
            <Page.Image src={searchImg} />
            <Page.PaddedDiv>
              <Page.H2>Wordpress Development</Page.H2>

              <Page.Paragraph>
                A proficient WordPress platform is the necessity of the hour to
                boost the effectiveness of your business website among visitors.
              </Page.Paragraph>

              <Page.Paragraph>
                With mark to this, We are one of the best wordpress development
                companies in vancouver who has in-depth experience in developing
                numerous WordPress projects successfully in the past few years.
                Also, We make a substantial contribution in developing WordPress
                websites such as its core, themes, plugins, & patches so as to
                build a robust technical solution that suits best for your
                digital business.
              </Page.Paragraph>

              <Page.List>
                <span>Easy to use Content Management System</span>
                <span>Search Engine Friendly</span>
                <span>Complete Design Customization</span>
                <span>Very Active Community Support</span>
                <span>Availabispanty of Several Plugins</span>
                <span>Cost-effectiveness</span>
              </Page.List>
            </Page.PaddedDiv>
          </Page.SectionWrapper>

          <Page.SectionWrapper>
            <Page.Image src={seoImg} />
            <Page.PaddedDiv>
              <Page.H2>Customized Developmen:</Page.H2>

              <Page.Paragraph>
                New world Innovations typically believe in developing dynamic
                experiences through a wide variety of custom web development
                solutions using Django, HTML,CSS, Vanilla Javascript, ReactJS &
                much more. For this, we combine various web elements for full
                range web development scenarios, extraordinary concepts, and
                business models.
              </Page.Paragraph>

              <Page.Paragraph>
                We have achieved custom web development as our core competency
                with complex time-honored methods. Although,We strive to deliver
                personalized & high-quality robust website development in an
                efficient & cost-effective manner for all our customers in
                Vancouver.
              </Page.Paragraph>

              <Page.List>
                <span>Simplicity of Syntaxes</span>
                <span>Impressive Speed & Productivity</span>
                <span>Innovation & Customization</span>
                <span>Backward Compatible</span>
                <span>Secure & Upto Date</span>
                <span>Time Effective</span>
              </Page.List>
            </Page.PaddedDiv>
          </Page.SectionWrapper>
        </Page.PageWrap>
      </Page>
      <ContactSection />
    </div>
  );
}
