import { Unicon } from "components/Unicon";

const H2 = ({ children }: any) => (
  <h2 className="font-bold my-3 text-xl ubuntu-font">{children}</h2>
);

const Paragraph = ({ children }: any) => <p className="mb-3">{children}</p>;

const PaddedDiv = ({ children }: any) => <div className="p-5">{children}</div>;

const PageWrap = ({ children }: any) => (
  <div className="bg-white shadow-md mdd:p-10 rounded p-3">{children}</div>
);

const SectionWrapper = ({ children, left = false }: any) => {
  if (children.length !== 2)
    throw new Error("SectionWrapper must have 2 children");

  return (
    <section>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
        <div className="md:order-2 p-5 md:p-5">{children[left ? 0 : 1]}</div>
        <div className="order-1 flex items-center">
          <div className="h-full w-full flex items-center">
            {children[left ? 1 : 0]}
          </div>
        </div>
      </div>
    </section>
  );
};

const List = ({ children }: any) => (
  <ul>
    {children.map((child: any) => (
      <li>
        <Unicon name="check-circle" className="mr-1 text-green-500" />
        {child}
      </li>
    ))}
  </ul>
);

const Image = ({ src }: any) => (
  <img src={src} className="w-1/2 md:w-3/4 mx-auto" />
);

export default function Page({ title, children, className = "" }: any) {
  return (
    <div className={`container mx-auto pt-10 ${className}`}>
      <h1 className="text-2xl mb-10">{title}</h1>
      {children}
    </div>
  );
}

Page.H2 = H2;
Page.Paragraph = Paragraph;
Page.PaddedDiv = PaddedDiv;
Page.PageWrap = PageWrap;
Page.SectionWrapper = SectionWrapper;
Page.List = List;
Page.Image = Image;
