import React from "react";
import "./Navbar.css";

import { Unicon } from "components/Unicon";

import { LOGO_250 } from "../../constants";
import { Navlink } from "components/Navlink";

export default function Navbar() {
  return (
    <div className="w-full shadow-md bg-white p-5">
      <div className="container mx-auto">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row align-center">
            <Unicon className="block md:hidden text-2xl mr-2" name="bars" />
            <img className="h-10" src={LOGO_250} />
          </div>
          <Unicon className="text-2xl md:hidden" name="cog" />
          <div className="md:items-center hidden md:flex">
            <Navlink pathname="/">Home</Navlink>
            <Navlink pathname="/mobile-development/">Mobile</Navlink>
            {/* <Navlink pathname="/our-team/">Our Team</Navlink> */}
            <Navlink pathname="/search-engine-optimization/">SEO</Navlink>
            <Navlink pathname="/web-design-and-development/">
              Web Development
            </Navlink>
            <Navlink pathname="/game-development/">Game Development</Navlink>
            {/* <Navlink pathname="/about/">About</Navlink> */}
            <Navlink pathname="/contact/">Contact</Navlink>
          </div>
        </div>
      </div>
    </div>
  );
}
