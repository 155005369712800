import React from "react";
import { Link, useLocation } from "react-router-dom";

interface NavlinkProps {
  pathname: string;
  children: React.ReactNode;
}

export default function Navlink({ pathname, children }: NavlinkProps) {
  const { pathname: path } = useLocation();

  return (
    <Link
      className={`inline mr-4 nav-link ${path === pathname ? "active" : ""}`}
      to={pathname}
    >
      {children}
    </Link>
  );
}
