import ContactForm from "components/Forms/ContactForm";
import React from "react";

export default function ContactSection() {
  return (
    <section className="my-5">
      <div className="py-10">
        <div className="container mx-auto">
          <div className="md:w-2/3 mx-auto p-3">
            <h2 className="text-3xl font-bold text-center">
              We'd like to hear from you
            </h2>
            <p className="text-lg mb-3 text-center w-5/6 md:w-full mx-auto">
              To request a quote or want to meet for a coffee, contact us
              directly or fill out the form below and we will get back to you as
              soon as we can.
            </p>

            <ContactForm />
          </div>
          <div className="md:w-1/2"></div>
        </div>
      </div>
    </section>
  );
}
