// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/laptop-bg.jpeg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/computer-min.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body {\n  background-color: rgb(250, 250, 250);\n}\n\n.body .section-1 {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  height: 50vh;\n}\n\n.body p {\n  font-family: \"Gill Sans\", \"Gill Sans MT\", Calibri, \"Trebuchet MS\", sans-serif;\n}\n\n.bg-computer {\n  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: cover;\n  background-position: center;\n}\n\n@media screen and (min-width: 768px) {\n  .section-1 {\n    height: 70vh !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/containers/Body/Body.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,yDAA8C;EAC9C,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,6EAA6E;AAC/E;;AAEA;EACE;2CACiC;EACjC,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".body {\n  background-color: rgb(250, 250, 250);\n}\n\n.body .section-1 {\n  background-image: url(\"assets/laptop-bg.jpeg\");\n  background-size: cover;\n  height: 50vh;\n}\n\n.body p {\n  font-family: \"Gill Sans\", \"Gill Sans MT\", Calibri, \"Trebuchet MS\", sans-serif;\n}\n\n.bg-computer {\n  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),\n    url(\"assets/computer-min.jpeg\");\n  background-size: cover;\n  background-position: center;\n}\n\n@media screen and (min-width: 768px) {\n  .section-1 {\n    height: 70vh !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
