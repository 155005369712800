import React from "react";
import seoImg from "assets/images/seo.png";
import webDesignImg from "assets/images/web-design.png";
import searchImg from "assets/images/search.png";
import { Unicon } from "components/Unicon";
import ContactSection from "containers/ContactSection";

export default function SEO() {
  return (
    <div className="container mx-auto py-10">
      <h1 className="text-2xl mb-10">Search Engine Optimization</h1>

      <div className="bg-white shadow-md mdd:p-10 rounded p-3">
        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="md:order-2 p-5 md:p-5">
              <div className="h-full w-full flex items-center">
                <img src={seoImg} className="w-1/2 md:w-3/4 mx-auto" />
              </div>
            </div>
            <div className="order-1 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-2 text-xl">Why Do I Need SEO?</h2>
                <p className="mb-3">
                  SEO is the future and it's better to believe it. If you wait
                  for it until a long time, then the game will be over or there
                  might be no coming back and however it will be by time, all
                  those top positions in your similar niche or industry will be
                  filled by your competition & you will never be able to beat
                  them and gain public traction.
                </p>

                <p className="mb-3">
                  Search engines like Google crawls the internet to compile a
                  database of web pages they find and provide an array of web
                  pages ranked by relevance and Quality score
                </p>

                <p className="mb-3">
                  When you make a query or search a keyphrase/keyword in a
                  specific search engine, it searches through its vast database
                  of web pages to deliver a search engine results page (SERP)
                  with pages ranked by relevance, popularity and quality score.
                  The more relevant and qualitative a result, the higher it will
                  rank and the more people click on it. So by this, more traffic
                  goes to that website.
                </p>

                <p className="mb-3">
                  Google and other search engines use several algorithms that
                  evaluate hundreds of search signals into account with a goal
                  is to determine how well your web page answers a query. Then
                  they rank your web page or specific URL, in part, by how many
                  websites are linking to you, which we often announce as
                  popularity.
                </p>

                <p className="mb-3">
                  Although, this vast majority of web traffic comes from just 3
                  major search engines: Google, Bing, & Yahoo. Increased traffic
                  from these search engines gives you more exposure, customers,
                  & revenue unlike anything else. That is why investing in SEO
                  can deliver an exceptional rate of return compared to other
                  forms of marketing that pay you much more.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="order-2 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-3 text-xl">
                  SEO Services that Bring Organic Traffic
                </h2>
                <h2 className="text-lg font-bold mb-3">
                  <Unicon name="info-circle text-blue-600" className="mr-2" />
                  On-Page Optimization
                </h2>

                <p className="mb-2">
                  The architecture of a webpage, technicality & content is only
                  a piece to the SEO puzzle, but a very mandatory one. To fulfil
                  this, we perform world class onpage-optimization with our
                  proven techniques so that your website will be crawled by
                  google bots.
                </p>

                <p className="mb-2 mx-2">
                  <Unicon name="check-circle text-green-600 " /> Architecture
                  Analysis: We go through your website page by page to make sure
                  that the pages are well optimized with an eye of a
                  professional along with the public so that there would not be
                  any dis-functionality or bugs in it.
                </p>

                <p className="mb-2 mx-2">
                  <Unicon name="check-circle text-green-600 " /> Technical Audit
                  & Curation: Even after this, there are 100+ technical factors
                  on which google crawl bot will judge your website including
                  Ongraph protocols, 404 pages, 302 errors, headings & much
                  more.
                </p>

                <p className="mb-2 mx-2">
                  <Unicon name="check-circle text-green-600 " /> Keyword
                  targeting: We research to find the best performing keywords in
                  your niche with our set of tools & techniques and assign them
                  to the appropriate pages on your website with meta tags and by
                  curating on page content.
                </p>

                <h2 className="font-bold text-lg mb-3">
                  <Unicon name="info-circle text-blue-600 mr-2" />
                  Off-Page Optimization
                </h2>

                <p className="mb-2">
                  The best SEO agencies are the ones that focus on the quality
                  of backlinks versus the quantity of links.Well for that we are
                  here for you and provide a complete list of backlink reports
                  after every 15 days for full transparency.
                </p>

                <p className="mb-2 mx-2">
                  <Unicon name="check-circle text-green-600" className="mr-2" />
                  Valuable industry citations: We seek out backlinks from
                  various authoritative resources such as major business
                  listings and niche directories so you would be find out more
                  by your audience
                </p>

                <p className="mb-2 mx-2">
                  <Unicon name="check-circle text-green-600" className="mr-2" />
                  Effective content marketing: Offering valuable content not
                  only assists build your company into an authority, but
                  provides an easy way to showcase to your audience how your
                  services will be helpful for them.
                </p>

                <p className="mb-2 mx-2">
                  <Unicon name="check-circle text-green-600" className="mr-2" />
                  Earned links through outreach: People are everywhere and you
                  cant detect where your next customer will come from. For this,
                  We have developed a list of high DA PA websites that offer
                  free backlinks along with providing an effective gesture to
                  find you more often.
                </p>
              </div>
            </div>
            <div className="md:order-1 md:p-5">
              <div className="h-full w-full flex items-center">
                <img src={searchImg} className="w-1/2 md:w-3/4 mx-auto" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="order-1 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-3 text-xl">
                  Content Writing Services
                </h2>
                <p className="mb-3">
                  Every individual who is looking for professional content
                  writing services deserves only the best . to fulfil ths, We
                  assure that our customers get exclusive, plagiarism-free and
                  well researched content at his required timeline. We are
                  always dedicated to our quality of work with and have the
                  expertise in each and every industry so far. That is why we
                  claim to be among one of the best content writing services in
                  Vancouver.
                </p>

                <p className="mb-3">
                  Website Content Services: Your website tells visitors who you
                  are & what you do. The best websites assist customers by
                  providing the USP factors along with all the necessary details
                  needed to make smart choices. From product & service
                  descriptions to company details such as policies.Our content
                  writing team can breathe new life into your website.
                </p>

                <p className="mb-3">
                  Personal Blog Posts: Blogs are an impactful way to connect
                  with others on the internet, writing on a regular basis takes
                  time, though, and even the most activist bloggers sometimes
                  need help. That’s where we stood up and were available to pick
                  you up where you left off and develop a piece of content you
                  deserve.
                </p>

                <p className="mb-3">
                  Business Blog Posts: With Similarity to personal blogs,
                  Business blogs help you stay in contact and make a way to
                  showcase your product or service to customers. Your team has
                  way bigger things to do than posting a blog on a weekly or
                  daily basis, Dont worry and let us take care of it.
                </p>
              </div>
            </div>
            <div className="md:order-1 md:p-5">
              <div className="h-full w-full flex items-center">
                <img src={webDesignImg} className="w-1/2 md:w-3/4 mx-auto" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <ContactSection />
    </div>
  );
}
