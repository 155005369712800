import ContactForm from "components/Forms/ContactForm";
import Page from "components/Page";
import React from "react";

export default function Contact() {
  return (
    <Page title="Contact Us" className="pb-5">
      <Page.PageWrap>
        <ContactForm className="!shadow-none" />
      </Page.PageWrap>
    </Page>
  );
}
