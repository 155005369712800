import ContactForm from "components/Forms/ContactForm";
import React from "react";
import appDevImg from "assets/images/app-dev.png";
import codingImg from "assets/images/coding.png";
import dataAnalysisImg from "assets/images/data-analysis.png";
import mobileNotificationImg from "assets/images/mobile-notification.png";
import workspaceImg from "assets/images/workspace.png";
import ContactSection from "containers/ContactSection";

export default function Solutions() {
  return (
    <div className="container mx-auto py-10">
      <h1 className="text-2xl mb-10">Mobile App Development</h1>

      <section>
        <div className="bg-white shadow-md mdd:p-10 rounded p-3">
          <div className="grid grid-cols-1">
            <div className="p-5">
              <h2 className="font-bold my-2 text-xl">
                Mobile Application Development In Vancouver
              </h2>
              <p className="mb-2">
                Ignite your business with personalized mobile applications
                developed by New World Innovations. We are among one of the best
                mobile application development companies in Vancouver that can
                assist you in developing digitally transformative,
                high-performing, & featured-packed native apps that work both
                for Android and iOS devices.
              </p>
              {/* <Button>Click for Free Consultation</Button> */}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="md:order-1 p-5 md:p-5">
              <div className="h-full w-full flex items-center">
                <img src={dataAnalysisImg} className="w-1/2 md:w-3/4 mx-auto" />
              </div>
            </div>
            <div className="order-1 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-2 text-xl">
                  Delivering Fully Functional Mobile Apps For All
                </h2>
                <p className="mb-2">
                  As mobile visitors continue to grow, we understand the
                  increasing importance of mobile applications very much.
                  Whether you are looking to extend your marketing efforts or
                  have the next great app concept, our team is ready to bring
                  your ideas to a bold reality.
                </p>

                <p className="mb-2">
                  Our experienced team can help you design & develop mobile apps
                  for all major platforms including iOS and Android.Our mobile
                  application developers in Vancouver can help you to build
                  responsive mobile applications from the scratch that aligns
                  with your business objectives completely while adding
                  beautiful and eye catchy UI/UX to it.
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="order-1 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-2 text-xl">
                  Enrich The Seed Of Your Idea With Our Mobile App Development
                  Services
                </h2>
                <p className="mb-2">
                  New World Innovation forges excellent mobile applications that
                  are feature-rich & user-friendly to your business core.
                  Although, the mobile applications developed by us are scalable
                  and made with clean codes so that Google play and app store
                  would approve them.
                </p>
              </div>
            </div>
            <div className="md:order-1 md:p-5">
              <div className="h-full w-full flex items-center">
                <img src={appDevImg} className="w-1/2 md:w-3/4 mx-auto" />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="md:order-1 md:p-10">
              <div className="h-full w-full flex items-center">
                <img src={codingImg} className="w-1/2 md:w-3/4 mx-auto" />
              </div>
            </div>
            <div className="order-1 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-2 text-xl">
                  Android App Development
                </h2>
                <p className="mb-2">
                  Android is an Operating System used by over a large part of
                  people. Individuals invest a large portion of their time
                  running applications since it makes it easier for them than
                  operating a website on mobile.
                </p>
                <p className="mb-2">
                  Our Android application development is superior as each
                  progression in development is initiated by keeping in mind the
                  right principal approach. Our mobile app developers are much
                  acquainted with android programming which gives an
                  extraordinary plan & execution of the project in an all-around
                  settled way. Though we have already developed many scalable
                  apps ruling the Google play store.{" "}
                </p>
              </div>

              {/* <h2 className="font-bold my-2 text-lg">
                Key Benefits of Android Mobile Application Development
              </h2>
              <ul className="pl-3">
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;Scalable Application with Flawless UI & UX
                  </span>
                </li>
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;Background Services for an everlasting impression
                  </span>
                </li>
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;Seamless App Navigation{" "}
                  </span>
                </li>
              </ul> */}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="order-1 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-2 text-xl">iOS App Development</h2>

                <p className="mb-2">
                  With years of experience, New World Innovations is the master
                  in developing secure iOS mobile apps by providing a wide scope
                  of tricks. We use the most latest and complex advancements in
                  iPhone app development which are the reason for our
                  achievement as the best iPhone app development company in
                  Vancouver.
                </p>

                <p className="mb-2">
                  Although building up an application in iOS requires the most
                  creative set of ingredients as well as a bunch of specialists,
                  New World Innovations strives to provide you with the most
                  satisfying and esteem driven iPhone application to cater to
                  all your business needs.
                </p>
              </div>

              {/* <div>
              <h2 className="font-bold my-2 text-xl">
                Key Benefits of iOS Mobile Application Development
              </h2>
              <ul className="pl-3">
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;Callkit Framework for VOIP Integration
                  </span>
                </li>
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;Speech Recognition
                  </span>
                </li>
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;SIRI for easy voice interface
                  </span>
                </li>
              </ul>
              </div> */}
            </div>
            <div className="md:order-1 md:p-10">
              <div className="h-full w-full flex items-center">
                <img src={workspaceImg} className="w-1/2 md:w-3/4 mx-auto" />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div className="md:order-1 md:p-10">
              <div className="h-full w-full flex items-center">
                <img
                  src={mobileNotificationImg}
                  className="w-1/2 md:w-3/4 mx-auto"
                />
              </div>
            </div>
            <div className="order-1 flex items-center">
              <div className="p-5">
                <h2 className="font-bold my-2 text-xl">
                  Native App Development
                </h2>
                <p className="mb-2">
                  Native mobile app development can be a vigorous path for your
                  digital business as you need to gather a successful presence
                  in the steadily mobile ecosystem. Nevertheless, business
                  owners must clarify the potential critical effect of their
                  development options before picking a solution for them.
                </p>

                <p className="mb-2">
                  At New World Innovations, Our professionals believe in
                  enjoining you about the right platform according to your needs
                  & how it could impact in the best possible way. Although in
                  native languages, Application performance in terms of nimble &
                  fluid animations is much prominent which makes the native
                  applications win among your competition.
                </p>
              </div>
            </div>
            {/*<div>
              <h2 className="font-bold my-2 text-xl">
                Key Benefits of Native Mobile Application Development:
              </h2>
              <ul>
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;Allows full device features such as USB input, complex
                    networking, etc.
                  </span>
                </li>
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;Delivers great performance and speed with fewer bugs.
                  </span>
                </li>
                <li>
                  <span className="text-sm">
                    <Unicon name="check" />
                    &nbsp;It provides no limitations in terms of advanced user
                    interface customization.
                  </span>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </section>

      <ContactSection />
    </div>
  );
}
