import Page from "components/Page";
import React from "react";
import webDesignImg from "assets/images/web-design.png";

export default function GameDev() {
  return (
    <Page title="Your Partner in Cutting-Edge Game Design & Development" className="pb-5">
      <Page.PageWrap>
        <Page.SectionWrapper>
          <Page.Image src={webDesignImg} />

          <Page.PaddedDiv>
            <Page.H2>
              We are passionate about creating immersive and captivating gaming experiences.
            </Page.H2>

            <Page.Paragraph>
               With a strong focus on Unreal Engine 5 and C++, we are dedicated to pushing the boundaries of what's possible in the gaming industry. Our team of talented developers, designers, and artists works tirelessly to bring your gaming visions to life. Also We are open to other engines like Unity, Godot and so on.
              
            </Page.Paragraph>
            {/* We specialize in designing and developing video games that leave a lasting impact. Our expertise in Unreal Engine 5 and C++ ensures that your game not only meets but exceeds industry standards. */}

            {/* Our team of level designers creates intricate and visually stunning game environments that immerse players in captivating worlds. */}

            {/* We bring characters to life with realistic animations and engaging storytelling, giving your games a sense of depth and personality. */}

            {/* From cooperative adventures to intense online battles, we excel at crafting seamless and thrilling multiplayer experiences. */}

            {/* We explore the exciting world of virtual reality (VR) and augmented reality (AR) to create immersive and unforgettable gaming experiences. */}

            <Page.List>
              <span>Game Development</span>
              
              <span>Level Design</span>
              <span>Character Design & Animation</span>
              <span>Multiplayer Development</span>
              <span>VR Integration</span>
            </Page.List>
          </Page.PaddedDiv>
        </Page.SectionWrapper>
      </Page.PageWrap>
    </Page>
  );
}
