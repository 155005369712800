import { Button } from "components/Button";
import React, { createRef, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

export default function ContactForm({ className = "" }: any) {
  const form: any = useRef();
  const [isHuman, setIsHuman] = useState(false);
  const [loading, setLoading] = useState(false);
  const recaptchaRef: any = createRef();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!isHuman) {
      alert("Please verify that you are a human");
      return;
    }

    if (
      process.env.REACT_APP_EMAIL_SERVICE_ID &&
      process.env.REACT_APP_CONTACT_EMAIL_TEMPLATE_ID &&
      process.env.REACT_APP_EMAIL_USER_ID
    ) {
      setLoading(true);
      try {
        const result = await emailjs.sendForm(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_CONTACT_EMAIL_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_EMAIL_USER_ID
        );
      } catch (error: any) {
        console.log(error.text);
      } finally {
        setLoading(false);
      }
    }
  };

  const onChange = (value: any) => {
    setIsHuman(true);
  };

  return (
    <form
      ref={form}
      className={`bg-white p-5 shadow-md ${className}`}
      onSubmit={handleSubmit}
    >
      <div className="w-100 p-3">
        <div className="flex flex-col">
          <label className="mb-3" htmlFor="name">
            Name
          </label>
          <input
            className="p-3 bg-gray-100"
            name="user_name"
            placeholder="John Doe"
          />
        </div>
      </div>

      <div className="w-100 p-3">
        <div className="flex flex-col">
          <label className="mb-3" htmlFor="email">
            Email
          </label>
          <input
            className="p-3 bg-gray-100"
            name="user_email"
            placeholder="johndoe@example.com"
          />
        </div>
      </div>

      <div className="w-100 p-3">
        <div className="flex flex-col">
          <label className="mb-3" htmlFor="message">
            Message
          </label>
          <textarea
            className="p-3 bg-gray-100"
            name="message"
            placeholder="Enter your message here"
          />
        </div>
      </div>

      {process.env.REACT_APP_RECAPTCHA_KEY && (
        <div className="flex items-center justify-center">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={onChange}
          />
        </div>
      )}

      <div className="w-100 p-3">
        <Button className="block text-white uppercase">Submit</Button>
      </div>
    </form>
  );
}
